<template>
  <div class="searchUser">
    <div class="searchUser-cont">
      <div class="searchUser-cont-texts" v-show="!searchStatus">
        <button class="searchUser-cont-texts-face"></button>
        <div class="searchUser-cont-texts-title">您想要查找哪位会员?</div>
        <div class="searchUser-cont-texts-info">
          可对 会员详情、照片、充值等进行操作...
        </div>
        <div class="searchUser-cont-texts-input">
          <el-select v-model="value" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled"
              style="border:none;"
            >
            </el-option>
          </el-select>
          <el-input
            style="width:250px;border-left:1px solid #d4d4d5;"
            v-model="input"
            placeholder="请输入搜索关键字"
          ></el-input>
          <button
            class="searchUser-cont-texts-input-button"
            style="border:none;"
            @click="searchShow"
            :class="
              input.length ? 'searchUser-cont-texts-input-button-active' : ''
            "
          >
            查询
          </button>
        </div>
      </div>

      <div
        class="searchUser-cont-list"
        :class="searchStatus ? 'searchUser-cont-list-active' : ''"
      >
        <i class="el-icon-circle-close" @click="clearSearch"></i>
        <div class="searchUser-cont-texts-input searchUser-cont-list-input">
          <el-select v-model="value" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled"
            >
            </el-option>
          </el-select>
          <el-input
            style="width:250px;border-left:1px solid #d4d4d5;"
            v-model="input"
            clearable
            @clear="clearSearch"
            placeholder="请输入内容"
          ></el-input>
          <button
            class="searchUser-cont-texts-input-button"
            @click="searchShow"
            :class="
              input.length ? 'searchUser-cont-texts-input-button-active' : ''
            "
          >
            查询
          </button>
        </div>
        <div class="searchUser-cont-list-head">
          <div>卡号</div>
          <div>姓名</div>
          <div>手机号</div>
          <div>卡类型</div>
          <div>储值余额</div>
          <div>归属门店</div>
        </div>
        <div class="searchUser-cont-list-item">
          <div
            class="searchUser-cont-list-item-list"
            v-for="(item, index) in searchData"
            :key="index"
            @click="userActive(index)"
          >
            <div class="searchUser-cont-list-item-list-viewimg">
              <img :src="item.head_picture" alt="" />
            </div>
            <div class="searchUser-cont-list-item-list-view">
              {{ item.card }}
            </div>
            <div class="searchUser-cont-list-item-list-view">
              {{ item.name }}
            </div>
            <div class="searchUser-cont-list-item-list-view">
              {{ item.phone }}
            </div>
            <div class="searchUser-cont-list-item-list-view">
              {{ item.membertype.name }}
            </div>
            <div class="searchUser-cont-list-item-list-view">
              {{ item.amount }}
            </div>
            <div class="searchUser-cont-list-item-list-view">
              {{ item.superior || "平台" }}
            </div>
          </div>
        </div>
        <el-pagination background layout="prev, pager, next" :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "selectVips",
  data() {
    return {
      options: [
        {
          value: "0",
          label: "号码",
        },
        {
          value: "1",
          label: "姓名",
        },
      ],
      value: "0", //搜索类型
      input: "", //搜索关键字
      searchStatus: false, //显示搜索状态
      user: [], //会员数据
      searchData: [], //搜索会员数据
      page: 1,
      total: 1,
      perPage: 15,
    };
  },
  methods: {
    hide() {
      this.$store.commit("selectVipShows", false);
    },
    getUser(obj) {
      let _this = this;
      this.$axios({
        href: "/api/app/meiye/member/store",
        data: { keyword: obj, name: obj, page: this.page, perPage: this.total },
      }).then((res) => {
        console.log(res);
        _this.user = res.data.items;
        _this.searchData = res.data.items;
        _this.total = Math.ceil(res.data.total/15);
        if (!res.data.items.length) {
          _this.$message({
            message: "请更换搜索关键字查询~",
            type: "warning",
          });
        }
      });
    },
    searchShow() {
      if (!this.input) {
        this.$message({
          message: "请输入查询条件",
          type: "warning",
        });
        return false;
      }
      this.getUser(this.input);
      // this.searchData = [];
      // let data = this.user;
      // let len = data.length;

      // for (let i = 0; i < len; i++) {
      //   if (this.value == "0" && data[i].phone.includes(this.input)) {
      //     this.searchData.push(data[i]);
      //   }
      //   if (this.value == "1" && data[i].name.includes(this.input)) {
      //     this.searchData.push(data[i]);
      //   }
      //   if (i == len - 1) {
      //     if (!this.searchData.length) {
      //       this.$message({
      //         message: "请更换搜索关键字查询~",
      //         type: "warning",
      //       });
      //     }
      //   }
      // }

      this.searchStatus = true;
    },
    clearSearch() {
      this.input = "";
      this.searchStatus = !this.searchStatus;
    },
    /* 选中的会员 */
    userActive(index) {
      console.log(this.searchData[index]);
      this.$store.commit("setUser", this.searchData[index]);
      this.$store.commit("selectVipShows", false);
    },
    sizeChange(page) {
      this.page = page;
      this.getUser(this.input);
    },
  },
  computed: {
    shows: {
      get() {
        return this.$store.state.order.selectVip;
      },
      set() {
        return this.$store.state.order.selectVip;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.searchUser {
  width: 100%;
  height: 100%;
  &-cont {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    margin: auto;
    background: #f8f8f8
      url(https://static.bokao2o.com/wisdomDesk/images/Def_SearchCard_BcImg.png)
      top / 100% no-repeat;
    overflow: hidden;
    &-texts {
      position: relative;
      width: 400px;
      height: 170px;
      left: 0;
      right: 0;
      margin: 180px auto 0 auto;
      -webkit-transition: -webkit-transform 0.7s, background 0.7s,
        font-size 0.7s;
      &-face {
        position: absolute;
        width: 44px;
        height: 44px;
        background: transparent
          url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_AuthFace.png)
          center / 28px no-repeat;
        top: 95px;
        right: -100px;
        cursor: pointer;
        border: none;
        outline: none;
        box-sizing: border-box;
      }
      &-title {
        font-family: PingFangSC-Semibold;
        font-size: 24px;
        color: #28282d;
        line-height: 40px;
        text-align: left;
      }
      &-info {
        margin-top: 5px;
        line-height: 20px;
        color: #5a5a5a;
        font-size: 15px;
        margin-bottom: 30px;
        text-align: left;
      }
      &-input {
        margin-top: 10px;
        display: flex;
        background: #fff;
        border-radius: 6px;
        overflow: hidden;
        height: 44px;
        &-button {
          width: 70px;
          height: 44px;
          padding: 0;
          line-height: 44px;
          font-family: PingFangSC-Semibold;
          font-size: 16px;
          color: #fff;
          background: #d4d4d5;
          cursor: pointer;
          border: none;
          outline: none;
          box-sizing: border-box;
          transition: all 0.5s;
        }
        &-button-active {
          background: #28282d;
        }
      }
    }
    &-list {
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      height: 0;
      top: 100%;
      overflow: hidden;
      padding-top: 0px;
      //   background: red;
      transition: all 0.5s;
      .el-icon-circle-close {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 25px;
      }
      &-head {
        width: 780px;
        height: 55px;
        margin: 0 auto;
        display: flex;
        background: #fff;
        padding-left: 110px;
        padding-right: 10px;
        div {
          flex: 1;
          text-align: center;
          line-height: 55px;
          font-size: 14px;
          color: #8a8a8a;
        }
      }
      &-item {
        width: 900px;
        height: 650px;
        margin: 0 auto;
        margin-bottom: 10px;
        text-align: center;
        background: #fff;
        overflow-x: hidden;
        overflow-y: auto;
        &-list:hover {
          cursor: pointer;
          background: #f4f4f4;
        }
        &-list {
          position: relative;
          display: flex;
          padding-left: 110px;
          box-sizing: border-box;
          margin-bottom: 25px;
          padding-right: 10px;
          background: #fff;
          -webkit-transition: -webkit-transform 0.3s, background 0.3s;
          div {
            flex: 1;
            padding: 12px 5px;
            line-height: 20px;
            font-size: 14px;
            color: #28282d;
            text-align: center;
          }
          &-view {
            transition: all 0.5s;
            text-align: center;
          }

          &-viewimg {
            position: absolute;
            left: 38px;
            top: 0;
            bottom: 0;
            width: 68px;
            height: 44px;
            padding: 0;
            margin: auto;
            img {
              width: 68px;
              height: 44px;
              border-radius: 5px;
            }
          }
        }
      }
      &-input {
        position: absolute;
        top: 30px;
        left: 0;
        right: 0;
        margin: auto;
        width: 400px;
        background: #fff;
        height: 44px;
        display: flex;
        border-radius: 6px;
        overflow: hidden;
      }
    }
    &-list-active {
      top: 0;
      height: 100%;
      padding-top: 85px;
      box-sizing: border-box;
      overflow: auto;
    }
  }
}

.searchUser-active {
  position: fixed;
  z-index: 3000;
  opacity: 1;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  //   transition: all 0.5s;
}
</style>

<style moudle>
.searchUser .el-select {
  width: 88px;
  height: 44px;
}
.searchUser .el-input__inner {
  height: 45px;
  background: #ffffff !important;
  color: #000 !important;
}
.searchUser .el-input__inner::placeholder {
  color: #000 !important;
}

.searchUser .el-select .el-input .el-select__caret {
  color: #000 !important;
}
.searchUser .el-input__inner {
  border: none;
  outline: none;
}
</style>
